import { lazy } from "react";

// PROTECTED ROUTES //
// Account setup routes
export const AccountSetup = lazy(() => import("./pages/setup/AccountSetup"));

// People routes
export const People = lazy(() => import("./pages/people/People"));
export const PeopleDetails = lazy(() => import("./pages/people/PeopleDetails"));

// Segment routes
export const Segments = lazy(() => import("./pages/segments/Segments"));
export const NewSegment = lazy(() => import("./pages/segments/NewSegment"));
export const DataDrivenSegment = lazy(
  () => import("./pages/segments/DataDrivenSegment"),
);
export const AutomatedSegment = lazy(
  () => import("./pages/segments/AutomatedSegment"),
);
export const SegmentDetails = lazy(
  () => import("./pages/segments/SegmentDetails"),
);

export const Overview = lazy(() => import("./pages/overview/Overview"));

// Campaign routes
export const Campaigns = lazy(() => import("./pages/campaigns/Campaigns"));
export const NewCampaign = lazy(() => import("./pages/campaigns/NewCampaign"));
export const CampaignDetails = lazy(
  () => import("./pages/campaigns/CampaignDetails"),
);

// Account Settings routes
export const ProfileDetails = lazy(
  () => import("./pages/settings/account/Profile"),
);
export const AccountDetails = lazy(
  () => import("./pages/settings/account/Account"),
);
export const TeamDetails = lazy(() => import("./pages/settings/account/Team"));
export const BillingOverview = lazy(
  () => import("./pages/settings/account/BillingOverview"),
);
export const BillingManagement = lazy(
  () => import("./pages/settings/account/BillingManagement"),
);
export const AccountSync = lazy(
  () => import("./pages/settings/account/CampaignEmails"),
);
export const AccountSyncSettings = lazy(
  () => import("./pages/settings/account/AccountSyncSettings"),
);
export const NotificationPreferences = lazy(
  () => import("./pages/settings/account/Notifications"),
);
export const Integrations = lazy(
  () => import("./pages/settings/account/Integration"),
);
export const IntegrationDetailsPage = lazy(
  () => import("./pages/settings/account/IntegrationDetailsPage"),
);
// Workspace Settings routes
export const WorkspaceDetails = lazy(
  () => import("./pages/settings/workspace/Workspace"),
);
export const WorkspaceManagement = lazy(
  () => import("./pages/settings/workspace/WorkspaceManagement"),
);
export const WorkspaceBlacklist = lazy(
  () => import("./pages/settings/workspace/WorkspaceBlacklist"),
);

// Calendar routes
export const Calendars = lazy(() => import("./pages/calendars/Calendars"));

// Templates routes
export const Templates = lazy(() => import("./pages/templates/TemplatesPage"));

// Tasks routes
export const Tasks = lazy(() => import("./pages/tasks/TasksPage"));

// Newsletters routes
export const Newsletters = lazy(
  () => import("./pages/newsletters/NewslettersPage"),
);
export const NewNewsletter = lazy(
  () => import("./pages/newsletters/NewNewsletter"),
);
export const NewsletterDetails = lazy(
  () => import("./pages/newsletters/NewsletterDetails"),
);
export const NewsletterDomains = lazy(
  () => import("./pages/settings/account/NewsletterDomains"),
);
export const NewsletterDomainDetails = lazy(
  () => import("./pages/settings/account/NewsletterDomainsDetails"),
);
export const AddNewsletterDomain = lazy(
  () => import("./pages/settings/account/AddNewsletterDomain"),
);

// Other
export const NotFound = lazy(() => import("./pages/NotFound"));

// UNPROTECTED ROUTES //
export const Login = lazy(() => import("./pages/authentication/Login"));
export const Signup = lazy(() => import("./pages/authentication/Signup"));
export const PasswordReset = lazy(
  () => import("./pages/authentication/PasswordReset"),
);
export const PasswordResetConfirm = lazy(
  () => import("./pages/authentication/PasswordResetConfirm"),
);
