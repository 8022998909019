import { Refresh } from "@mui/icons-material";
import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  Link,
  Stack,
  Toolbar,
  Tooltip,
  alpha,
  styled,
  useTheme
} from "@mui/material";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro";
import { ReactElement } from "react";
import { UbicoPagination } from "../../../constants/data-types";

const StyledToolbarContainer = styled(GridToolbarContainer)(() => ({
  // Toolbar style
}));

export interface UbicoToolbarActionProps {
  icon: React.ReactNode;
  onAction: any;
  tooltip: string;
  disabled?: boolean;
}

export interface UbicoToolbarActionGroupProps {
  id: number;
  actions: UbicoToolbarActionProps[];
}

interface DataGridToolbarProps {
  startActions: Array<UbicoToolbarActionGroupProps>;
  selectedAllPages?: boolean;
  onSelectAllPages?(): void;
  onClearSelection?(): void;
  numSelected?: number;
  pagination?: UbicoPagination;
  isLoadingData?: boolean;
  isLoadingAction?: boolean;
  showFilters?: boolean;
  startFilters?: ReactElement[];
  endFilters?: ReactElement[];
  onRefreshData?(): void;
}

const UbicoDataGridToolbar: React.FC<DataGridToolbarProps> = ({ ...props }) => {
  const {
    showFilters = true,
    startActions,
    pagination,
    isLoadingAction,
    isLoadingData,
    startFilters,
    endFilters,
    onClearSelection,
    onSelectAllPages,
    selectedAllPages = false,
    numSelected = 0,
    onRefreshData,
  } = props;
  const theme = useTheme();

  return (
    <StyledToolbarContainer
      sx={{
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity,
            ),
          borderRadius: theme.spacing(6),
          p: 0,
        }),
      }}
    >
      {showFilters ? (
        <Stack
          direction={'row'}
          alignItems={"center"}
          spacing={theme.spacing(2)} width={'100%'}
        >
          <Stack
            direction={"row"}
            spacing={theme.spacing(2)}
            alignItems={"center"} width={'100%'}
          >
            <GridToolbarQuickFilter
              sx={{ pb: 0, maxWidth: 150 }} InputProps={{ sx: { fontSize: "0.8rem" } }}
              variant="outlined"
              size="small"
              disabled={isLoadingData}
            />
            {onRefreshData && (
              <Tooltip title="Refresh data">
                <IconButton disabled={isLoadingData} onClick={onRefreshData} size="small">
                  <Refresh fontSize="small"/>
                </IconButton>
              </Tooltip>
            )}
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
              width={"100%"}
            >
              <Stack
                direction={"row"}
                alignItems={"center"}
                spacing={theme.spacing(2)}
              >
                {startFilters?.map((filter, index) => (
                  <div
                    key={index}
                    style={{
                      cursor: isLoadingData ? "none" : "pointer",
                      pointerEvents: isLoadingData ? "none" : "auto",
                      opacity: isLoadingData ? 0.5 : 1.0,
                    }}
                  >
                    {filter}
                  </div>
                ))}
              </Stack>
              <Stack
                direction={"row"}
                alignItems={"center"}
                spacing={theme.spacing(2)}
              >
                {endFilters?.map((filter, index) => (
                  <div
                    key={index}
                    style={{
                      cursor: isLoadingData ? "none" : "pointer",
                      pointerEvents: isLoadingData ? "none" : "auto",
                      opacity: isLoadingData ? 0.5 : 1.0,
                    }}
                  >
                    {filter}
                  </div>
                ))}
              </Stack>
            </Stack>
          </Stack>
          <Stack direction={'row'} spacing={theme.spacing(1)} alignItems={'center'}>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
          </Stack>
        </Stack>
      ) : (
        <Stack
          direction={"row"}
          width={"100%"}
          height={"10%"}
          alignItems={"center"}
          spacing={theme.spacing(1)}
          pl={theme.spacing(1)}
          pr={theme.spacing(1)}
          borderRadius={20}
        >
          <Toolbar variant="dense" disableGutters>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {isLoadingAction ? (
                <CircularProgress size={30} />
              ) : (
                numSelected > 0 &&
                startActions?.map((group) => (
                  <Stack
                    key={group?.id}
                    direction={"row"}
                    alignItems={"center"}
                    spacing={theme.spacing(1)}
                    justifyContent={"center"}
                  >
                    {group?.actions?.map((action, index) => (
                      <Tooltip title={action.tooltip} key={index}>
                        <span>
                          <IconButton
                            onClick={action.onAction}
                            disabled={action.disabled}
                          >
                            {action.icon}
                          </IconButton>
                        </span>
                      </Tooltip>
                    ))}
                    <Divider
                      orientation="vertical"
                      flexItem
                      variant="middle"
                      sx={{
                        mr: `${theme.spacing(1)} !important`,
                        mt: `${theme.spacing(1)} !important`,
                        mb: `${theme.spacing(1)} !important`,
                      }}
                    />
                  </Stack>
                ))
              )}
            </Box>
          </Toolbar>
          {numSelected > 0 && (
            <Chip
              size="small"
              color="default"
              label={`${numSelected} selected`}
            />
          )}
          {pagination &&
            onClearSelection &&
            onSelectAllPages &&
            !isLoadingData &&
            !isLoadingAction && (
              <Stack>
                {selectedAllPages ? (
                  <Link sx={{ cursor: "pointer" }} onClick={onClearSelection}>
                    Clear selection
                  </Link>
                ) : (
                  <Link sx={{ cursor: "pointer" }} onClick={onSelectAllPages}>
                    Select all {pagination.totalCount} records
                  </Link>
                )}
              </Stack>
            )}
        </Stack>
      )}
    </StyledToolbarContainer>
  );
};

export default UbicoDataGridToolbar;
