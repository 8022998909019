import { AxiosService, UbicoAPIResponse } from "../api-provider";
import { Profile } from "../constants/data-types";
import { API_PATH } from "../constants/paths";
import { GENERIC_ERROR_MESSAGE } from "../constants/snackbar-messages";

const updateProfile = async (
  profile_id: number,
  data: Profile,
): Promise<UbicoAPIResponse<Profile>> => {
  try {
    const account = await AxiosService.patch(
      `${API_PATH}/profile/${profile_id}/`,
      data,
    );
    return { error: false, data: account.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const updateAccountProfile = async (
  profile_id: number,
  data: Profile,
): Promise<UbicoAPIResponse<Profile>> => {
  try {
    const account = await AxiosService.patch(
      `${API_PATH}/account/profiles/${profile_id}/`,
      data,
    );
    return { error: false, data: account.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const getProfile = async (): Promise<UbicoAPIResponse<Profile>> => {
  try {
    const profile = await AxiosService.get(`${API_PATH}/profile/`);
    return { error: false, data: profile.data[0], message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const migrateProfile = async (body: object): Promise<UbicoAPIResponse<any>> => {
  try {
    await AxiosService.put(`${API_PATH}/profile/migrate/`, body);
    return { error: false, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const ProfileService = {
  getProfile,
  migrateProfile,
  updateProfile,
  updateAccountProfile,
};

export default ProfileService;
