import { ReactElement } from "react";
import UbicoStatusChip from "../../components/custom/StatusChip";

export const ACCOUNT_RUNNING = "running";
export const ACCOUNT_STOPPED = "stopped";
export const ACCOUNT_PARTIAL = "partial";
export const ACCOUNT_UNKNOWN = "unknown";

export const EMAIL_SYNC_STATUS_CHIPS: { [id: string]: ReactElement } = {};

EMAIL_SYNC_STATUS_CHIPS[ACCOUNT_RUNNING] = (
  <UbicoStatusChip label={"Running"} color="primary" />
);
EMAIL_SYNC_STATUS_CHIPS[ACCOUNT_STOPPED] = (
  <UbicoStatusChip label={"Stopped"} color="error" />
);
EMAIL_SYNC_STATUS_CHIPS[ACCOUNT_PARTIAL] = (
  <UbicoStatusChip
    label={"Partial"}
    color="warning"
    tooltip={"Partial state can be temporary"}
  />
);
EMAIL_SYNC_STATUS_CHIPS[ACCOUNT_UNKNOWN] = (
  <UbicoStatusChip
    label={"Unknown"}
    tooltip={"Try re-syncing your account"}
    color="info"
  />
);
