import { Stack } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { FC, ReactElement } from "react";
import UbicoProfileAvatar from "../../../components/custom/avatars/ProfileAvatar";

interface CalendarOwnerRenderProps {
  params: GridRenderCellParams;
}

const CalendarOwnerRender: FC<CalendarOwnerRenderProps> = (
  props,
): ReactElement => {
  const { params } = props;

  return (
    <Stack
      width={"100%"}
      height={"100%"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <UbicoProfileAvatar profileId={params.row.created_by} />
    </Stack>
  );
};

export default CalendarOwnerRender;
