import { Chip, ChipProps, Tooltip, styled } from "@mui/material";
import React from "react";

const StyledChip = styled(Chip)(({ theme }) => ({
  height: theme.spacing(3),
}));

interface UbicoStatusChipProps extends ChipProps {
  tooltip?: string;
}

const UbicoStatusChip: React.FC<UbicoStatusChipProps> = ({
  tooltip,
  ...props
}) => {
  if (tooltip)
    return (
      <Tooltip title={tooltip}>
        <StyledChip {...props} />
      </Tooltip>
    );
  else return <StyledChip {...props} />;
};

export default UbicoStatusChip;
