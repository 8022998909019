import { AxiosService, UbicoAPIResponse } from "../api-provider";
import { Calendar } from "../constants/data-types";
import { API_PATH } from "../constants/paths";
import { GENERIC_ERROR_MESSAGE } from "../constants/snackbar-messages";

const getCalendars = async (
  profile_id: number,
): Promise<UbicoAPIResponse<Array<Calendar>>> => {
  try {
    const calendars = await AxiosService.get(
      `${API_PATH}/emails/calendars/?profile_id=${profile_id}`,
    );
    return { error: false, data: calendars.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const getCalendarById = async (
  syncId: number,
  calendarId: number,
): Promise<UbicoAPIResponse<Calendar>> => {
  try {
    const calendar = await AxiosService.get(
      `${API_PATH}/emails/${syncId}/calendars/${calendarId}/`,
    );
    return { error: false, data: calendar.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const createCalendar = async (
  syncId: number,
  calendar: Calendar,
): Promise<UbicoAPIResponse<Array<Calendar>>> => {
  try {
    const calendars = await AxiosService.post(
      `${API_PATH}/emails/${syncId}/calendars/`,
      calendar,
    );
    return { error: false, data: calendars.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const deleteCalendar = async (
  syncId: number,
  calendarId: number,
): Promise<UbicoAPIResponse<Calendar>> => {
  try {
    const resp = await AxiosService.delete(
      `${API_PATH}/emails/${syncId}/calendars/${calendarId}/`,
    );
    return { error: false, data: resp.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};
const CalendarService = {
  getCalendars,
  getCalendarById,
  deleteCalendar,
  createCalendar,
};

export default CalendarService;
