export const CALENDAR_PREVIEW_LINK = "https://schedule.nylas.com";

export const CalendarStyle = {
  tintColor: "#32325d",
  backgroundColor: "white",
};

export enum CalendarFields {
  EmailAccountId = "email_id",
  Name = "name",
  Slug = "slug",
}
