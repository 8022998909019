import { Person } from "@mui/icons-material";
import {
  Avatar,
  ButtonProps,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { Profile } from "../../../constants/data-types";
import { RootState } from "../../../redux/store";

interface UbicoAvatarProps extends ButtonProps {
  selfProfile?: boolean;
  profileId?: number;
  width?: number;
  height?: number;
}

const StyledAvatar = styled(Avatar)(() => ({
  // Button style
}));

const UbicoProfileAvatar: React.FC<UbicoAvatarProps> = ({
  width = 24,
  height = 24,
  profileId = null,
  selfProfile = false,
}) => {
  const team = useSelector((state: RootState) => state.team);
  const profile = team.find((p: Profile) => p.id === profileId);
  const userProfile: Profile =
    useSelector((state: RootState) => state.profile) || {};

  const user = selfProfile ? userProfile?.user : profile?.user;
  const picture = selfProfile ? userProfile.picture : profile?.picture;

  return (
    <Tooltip
      title={
        user ? user?.first_name + " " + user?.last_name : "User no longer exist"
      }
    >
      <StyledAvatar
        alt={user?.first_name + " " + user?.last_name}
        src={picture}
        sx={{ width: width, height: height }}
        imgProps={{ referrerPolicy: "no-referrer" }}
      >
        {user ? (
          <Typography sx={{ fontSize: width / 2 }}>
            {user?.first_name?.[0] || ""}
            {user?.last_name?.[0] || ""}
          </Typography>
        ) : (
          <Person fontSize="small" />
        )}
      </StyledAvatar>
    </Tooltip>
  );
};

export default UbicoProfileAvatar;
