import { CalendarMonth } from "@mui/icons-material";
import { Icon } from "@mui/material";
import { COLLABORATOR_ROLE } from "../constants/user-roles";
import Calendars from "../pages/calendars/Calendars";
import { Route } from "./routeInterface";

export const calendarRoutes: Array<Route> = [
  {
    key: "calendars-route",
    label: "calendars",
    path: "calendars",
    enabled: true,
    component: Calendars,
    isTopTab: true,
    order: 5,
    tabInfo: {
      id: "calendars",
      startIcon: <Icon component={CalendarMonth} fontSize="small" />,
      endIcon: null,
    },
    leastRole: COLLABORATOR_ROLE,
  },
];
