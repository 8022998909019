import * as yup from "yup";
import { requiredStringValidator } from "../../../constants/validators";
import { CalendarFields } from "../constants/calendar";

export const calendarInitialValues = {
  [CalendarFields.EmailAccountId]: null,
  [CalendarFields.Name]: "",
  [CalendarFields.Slug]: "",
};

export const calendarSchema = yup.object().shape({
  [CalendarFields.EmailAccountId]: yup
    .number()
    .typeError("Email account is required")
    .required("Email account is required"),
  [CalendarFields.Name]: requiredStringValidator("Calendar name", 2, 50),
  [CalendarFields.Slug]: requiredStringValidator(
    "Calendar slug",
    0,
    50,
  ).matches(
    /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
    "Invalid slug format. Use lowercase alphanumeric characters separated by dashes.",
  ),
});
