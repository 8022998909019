import {
  Brightness4,
  Brightness7,
  KeyboardArrowRight,
  Logout,
} from "@mui/icons-material";
import {
  AppBar,
  Box,
  Fade,
  IconButton,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { FC, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ColorModeContext } from "../Providers";
import { UbicoSubscription } from "../constants/data-types";
import {
  NAVBAR_WIDTH_CLOSED,
  NAVBAR_WIDTH_OPENED,
} from "../constants/navbar/tabs";
import { BillingSubscriptionStatus } from "../pages/settings/account/constants/account-billing";
import { setColorMode } from "../redux/reducers/appReducer";
import { setAuth } from "../redux/reducers/authReducer";
import { openSnackbar } from "../redux/reducers/snackbarReducer";
import { RootState } from "../redux/store";
import { BILLING_ROUTE } from "../routes/appRoutes";
import AuthService from "../services/authService";
import { THEME_MODE } from "../theme";
import UbicoButton from "./custom/buttons/Button";
import UbicoNavLink from "./custom/links/UbicoNavLink";
import { NAVBAR_LEFT_ELEVATION } from "./navbar/left/NavbarLeft";

const StyledAppBar = styled(AppBar)<{ open?: boolean }>(({ theme, open }) => ({
  width: `calc(100% - ${open ? NAVBAR_WIDTH_OPENED : NAVBAR_WIDTH_CLOSED}px)`,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: open
      ? theme.transitions.duration.leavingScreen
      : theme.transitions.duration.enteringScreen,
  }),
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  zIndex: NAVBAR_LEFT_ELEVATION - 2,
}));

const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
  ".MuiBreadcrumbs-separator": {
    color: theme.palette.primary.main,
  },
  padding: theme.spacing(1.5),
  width: "fit-content",
  fontSize: theme.typography.subtitle2.fontSize,
}));

interface UbicoBreadcrumbsProps {
  open: boolean;
}

export const UbicoBreadcrumbs: FC<UbicoBreadcrumbsProps> = (props) => {
  const { open } = props;
  const location = useLocation();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const subscription: UbicoSubscription = useSelector(
    (state: RootState) => state.billing.subscription,
  );

  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const { state } = location;

  const { is_logged_in } = useSelector((state: RootState) => state.auth);
  const { toggleAppColorMode } = useContext(ColorModeContext);

  const toggleColorMode = () => {
    dispatch(setColorMode());
    toggleAppColorMode();
  };

  const logout = async () => {
    const resp = await AuthService.logout();
    if (resp.error) {
      dispatch(
        openSnackbar({
          message: "There was an error while logging out.",
          open: true,
          severity: "error",
        }),
      );
    }
    dispatch(setAuth({ is_logged_in: false }));
    navigate("/login");
  };

  const goToBilling = () => {
    navigate(BILLING_ROUTE);
  };

  useEffect(() => {
    const pathnames = location.pathname.split("/");
    const breadcrumbs = pathnames
      .map((pathname, index) => ({
        pathname,
        route: `${pathnames.slice(0, index + 1).join("/")}`,
      }))
      .filter((breadcrumb) => breadcrumb.pathname != "");
    setBreadcrumbs(breadcrumbs);
  }, [location]);

  const currentDate = new Date();
  const trialEnd = subscription?.trial_end
    ? new Date(subscription?.trial_end * 1000)
    : null;
  const trialDataDiff = trialEnd
    ? Math.abs(currentDate?.getTime() - trialEnd?.getTime())
    : null;
  const subscriptionEnd = trialDataDiff
    ? Math.ceil(trialDataDiff / (1000 * 3600 * 24))
    : null;

  return (
    <StyledAppBar position="fixed" elevation={0} open={open}>
      <Toolbar variant="dense" disableGutters>
        <Stack
          width={"100%"}
          direction={"row"}
          justifyContent={"space-between"}
          pr={theme.spacing(2)}
        >
          <StyledBreadcrumbs
            separator={<KeyboardArrowRight fontSize="small" />}
          >
            {breadcrumbs.map(({ pathname, route }, index) => {
              let displayPathname = pathname
                .split("-")
                .map((path: string) => {
                  return path.charAt(0).toUpperCase() + path.slice(1);
                })
                .join(" ")
                .toLowerCase();
              if (
                state?.urlMap &&
                Object.keys(state?.urlMap).includes(displayPathname)
              ) {
                displayPathname = state?.urlMap[displayPathname];
              }
              return index >= breadcrumbs.length - 1 ? (
                <Typography
                  color="primary"
                  variant="subtitle2"
                  key={route}
                  textTransform={"capitalize"}
                  fontWeight={"bolder"}
                >
                  {displayPathname}
                </Typography>
              ) : (
                <UbicoNavLink
                  color="primary"
                  key={route}
                  to={route}
                  style={{ textTransform: "capitalize" }}
                >
                  {displayPathname}
                </UbicoNavLink>
              );
            })}
          </StyledBreadcrumbs>
          <Stack
            direction={"row"}
            spacing={theme.spacing(2)}
            alignItems={"center"}
          >
            {subscription?.status === BillingSubscriptionStatus.Trialing && (
              <Fade
                in={subscription?.status === BillingSubscriptionStatus.Trialing}
                timeout={500}
              >
                <Box
                  bgcolor={theme.palette.action.hover}
                  p={theme.spacing(2)}
                  borderRadius={3}
                  height={30}
                  justifyContent={"center"}
                  alignItems={"center"}
                  display={"flex"}
                >
                  <Stack
                    direction={"row"}
                    spacing={theme.spacing(1)}
                    alignItems={"center"}
                  >
                    <Typography variant="caption">
                      Your trial ends in {subscriptionEnd}{" "}
                      {subscriptionEnd > 1 ? "days" : "day"}
                    </Typography>
                    <UbicoButton
                      sx={{ height: 22, textTransform: "capitalize" }}
                      variant="contained"
                      onClick={goToBilling}
                    >
                      Upgrade
                    </UbicoButton>
                  </Stack>
                </Box>
              </Fade>
            )}
            {subscription?.status === BillingSubscriptionStatus.PastDue && (
              <Fade
                in={subscription?.status === BillingSubscriptionStatus.PastDue}
                timeout={500}
              >
                <Box
                  bgcolor={theme.palette.action.hover}
                  p={theme.spacing(2)}
                  borderRadius={3}
                  height={30}
                  justifyContent={"center"}
                  alignItems={"center"}
                  display={"flex"}
                >
                  <Stack
                    direction={"row"}
                    spacing={theme.spacing(1)}
                    alignItems={"center"}
                  >
                    <Typography variant="caption">
                      Your payment is incomplete
                    </Typography>
                    <UbicoButton
                      color="error"
                      sx={{ height: 22, textTransform: "capitalize" }}
                      variant="contained"
                      onClick={goToBilling}
                    >
                      Update payment
                    </UbicoButton>
                  </Stack>
                </Box>
              </Fade>
            )}
            <Stack
              direction={"row"}
              spacing={theme.spacing(1)}
              alignItems={"center"}
            >
              <Tooltip
                title={
                  theme.palette.mode === THEME_MODE.Light
                    ? "Turn off the light"
                    : "Turn on the light"
                }
                placement="right"
              >
                <IconButton onClick={toggleColorMode} size="small">
                  {theme.palette.mode === THEME_MODE.Light ? (
                    <Brightness4 fontSize="small" />
                  ) : (
                    <Brightness7 fontSize="small" />
                  )}
                </IconButton>
              </Tooltip>
              {is_logged_in && (
                <IconButton size="small" onClick={logout}>
                  <Logout fontSize="small" />
                </IconButton>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Toolbar>
    </StyledAppBar>
  );
};
