import { Delete, Edit, MoreHoriz } from "@mui/icons-material";
import {
  Box,
  IconButton,
  List,
  ListItemButton,
  Paper,
  Popover,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { FC, ReactElement, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { CalendarStyle } from "../constants/calendar";
import CalendarDeleteDialog from "./CalendarDeleteDialog";

interface CalendarActionsRenderProps {
  params: GridRenderCellParams;
  onCompleteAction(): void;
}

const CalendarActionsRender: FC<CalendarActionsRenderProps> = (
  props,
): ReactElement => {
  const { params, onCompleteAction } = props;

  const profile = useSelector((state: RootState) => state.profile);

  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  const onCompleteDelete = () => {
    setShowDeleteConfirm(false);
    onCompleteAction();
  };

  const openNylasSchedule = () => {
    const appWindow: any = window;
    if (appWindow?.nylas?.scheduler) {
      appWindow.nylas.scheduler.show({
        auth: { pageEditToken: params.row?.edit_token },
        style: CalendarStyle,
      });
    }
  };

  const ownsCalendar = params.row?.created_by === profile.id;

  return (
    <Box>
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "center", horizontal: "left" }}
        transformOrigin={{ vertical: "center", horizontal: "right" }}
        onClose={handleClick}
      >
        <Paper>
          <List disablePadding>
            <ListItemButton
              sx={{ p: theme.spacing(1) }}
              dense
              onClick={openNylasSchedule}
              disabled={!ownsCalendar}
            >
              <Stack
                direction={"row"}
                spacing={theme.spacing(1)}
                alignItems={"center"}
              >
                <Edit fontSize="small" />
                <Typography variant="caption">Edit</Typography>
              </Stack>
            </ListItemButton>
            <ListItemButton
              sx={{ p: theme.spacing(1) }}
              dense
              onClick={() => setShowDeleteConfirm(true)}
              disabled={!ownsCalendar}
            >
              <Stack
                direction={"row"}
                spacing={theme.spacing(1)}
                alignItems={"center"}
              >
                <Delete fontSize="small" color="error" />
                <Typography variant="caption">Delete</Typography>
              </Stack>
            </ListItemButton>
          </List>
        </Paper>
      </Popover>
      <IconButton onClick={handleClick} size="small">
        <MoreHoriz fontSize="small" />
      </IconButton>
      <CalendarDeleteDialog
        open={showDeleteConfirm}
        setShowDeleteConfirm={setShowDeleteConfirm}
        selectedCalendar={params?.row}
        onComplete={onCompleteDelete}
      />
    </Box>
  );
};

export default CalendarActionsRender;
