import { Add } from "@mui/icons-material";
import { Stack, useTheme } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid-pro";
import { FC, ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import UbicoProfileAvatarSelector from "../../../components/custom/avatars/ProfileAvatarSelector";
import UbicoButton from "../../../components/custom/buttons/Button";
import UbicoDataGrid from "../../../components/custom/datagrids/DataGrid";
import UbicoDataGridToolbar from "../../../components/custom/datagrids/DataGridToolbar";
import { Calendar, Profile } from "../../../constants/data-types";
import { UserRoles } from "../../../constants/user-roles";
import { RootState } from "../../../redux/store";
import CalendarService from "../../../services/calendarService";
import { getTimeSince } from "../../../utils/date-utils";
import CalendarAccountRender from "./CalendarAccountRender";
import CalendarActionsRender from "./CalendarActionsRender";
import CalendarCreateDialog from "./CalendarCreateDialog";
import CalendarLinkRender from "./CalendarLinkRender";
import CalendarOwnerRender from "./CalendarOwnerRender";
import CalendarPreviewRender from "./CalendarPreviewRender";

const CalendarsTable: FC = (): ReactElement => {
  const theme = useTheme();

  const profile: Profile = useSelector((state: RootState) => state.profile);

  const [calendars, setCalendars] = useState<Array<Calendar>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showCreateCalendar, setShowCreateCalendar] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState<Profile>(profile);

  const getCalendars = async (profile: Profile) => {
    setIsLoading(true);
    const { error, data } = await CalendarService.getCalendars(profile?.id);
    if (!error && data) setCalendars(data);
    setIsLoading(false);
  };

  const refreshCalendars = () => {
    setShowCreateCalendar(false);
    getCalendars(selectedProfile);
  };

  const onProfileFilterChange = (profile: Profile) => {
    // If already filtered, remove from filter
    setSelectedProfile(profile);
  };

  useEffect(() => {
    if (selectedProfile) getCalendars(selectedProfile);
  }, [selectedProfile]);

  const calendarColumns: GridColDef[] = [
    {
      field: "name",
      headerName: "Calendar name",
      flex: 1,
      valueGetter: (_value, row, _column) => row.name,
    },
    {
      field: "slug",
      headerName: "Calendar slug",
      flex: 1,
      valueGetter: (_value, row, _column) => row.slug,
    },
    {
      field: "account",
      headerName: "Synced account",
      flex: 1,
      renderCell: (params) => <CalendarAccountRender params={params} />,
      filterable: false,
      sortable: false,
    },
    {
      field: "owner",
      headerName: "Owner",
      flex: 1,
      renderCell: (params) => <CalendarOwnerRender params={params} />,
      filterable: false,
      sortable: false,
    },
    {
      field: "link",
      headerName: "Link",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => <CalendarLinkRender params={params} />,
      filterable: false,
      sortable: false,
    },
    {
      field: "preview",
      headerName: "Preview",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => <CalendarPreviewRender params={params} />,
      filterable: false,
      sortable: false,
    },
    {
      field: "created_at",
      headerName: "Created",
      flex: 1,
      type: "date",
      renderCell: (params) => getTimeSince(params.value),
      valueGetter: (value, _row, _col) => new Date(value),
    },
    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      flex: 1,
      renderCell: (params) => (
        <CalendarActionsRender
          params={params}
          onCompleteAction={refreshCalendars}
        />
      ),
    },
  ];

  const CalendarsToolbar: FC = (): ReactElement => {
    return (
      <UbicoDataGridToolbar
        onRefreshData={() => getCalendars(selectedProfile)}
        isLoadingData={isLoading}
        startActions={[]}
        numSelected={0}
        startFilters={[
          <UbicoProfileAvatarSelector
            selectedProfiles={[selectedProfile]}
            onSelectProfile={onProfileFilterChange}
          />,
        ]}
      />
    );
  };

  return (
    <Stack spacing={theme.spacing(2)}>
      <Stack direction={"row"} justifyContent={"flex-end"}>
        <UbicoButton
          variant="contained"
          startIcon={<Add />}
          size="small"
          onClick={() => setShowCreateCalendar(true)}
          leastRole={UserRoles.Default}
        >
          Create calendar
        </UbicoButton>
      </Stack>
      <UbicoDataGrid
        sx={{
          width: "100%",
        }}
        columns={calendarColumns}
        rows={calendars}
        density="standard"
        slots={{ toolbar: CalendarsToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        getRowId={(row) => row.id}
        loading={isLoading}
      />
      <CalendarCreateDialog
        open={showCreateCalendar}
        onClose={refreshCalendars}
      />
    </Stack>
  );
};

export default CalendarsTable;
