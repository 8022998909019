import { NodeDataBase } from "../data-types";

export enum EmailOptions {
  Name = "name",
  IsThread = "is_thread",
  IsDraft = "is_draft",
  Sender = "sender_id",
  Subject = "subject",
  Body = "body",
}

export interface EmailData extends NodeDataBase {
  [EmailOptions.IsThread]: boolean;
  [EmailOptions.IsDraft]: boolean;
  [EmailOptions.Sender]: number;
  [EmailOptions.Subject]: string;
  [EmailOptions.Body]: string;
}
