import { WarningAmber } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Chip,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { FC, ReactElement, useState } from "react";
import { useDispatch } from "react-redux";
import UbicoDialog from "../../../components/custom/Dialog";
import UbicoButton from "../../../components/custom/buttons/Button";
import { Calendar } from "../../../constants/data-types";
import { openSnackbar } from "../../../redux/reducers/snackbarReducer";
import CalendarService from "../../../services/calendarService";

interface CalendarDeleteProps {
  open: boolean;
  setShowDeleteConfirm: any;
  selectedCalendar: Calendar;
  onComplete: any;
}

const CalendarDeleteDialog: FC<CalendarDeleteProps> = (props): ReactElement => {
  const { open, setShowDeleteConfirm, selectedCalendar, onComplete } = props;
  const theme = useTheme();
  const dispatch = useDispatch();

  const [isDeleting, setIsDeleting] = useState(false);

  const onDeletePeople = async () => {
    setIsDeleting(true);
    const { error, message } = await CalendarService.deleteCalendar(
      selectedCalendar?.account_id,
      selectedCalendar?.id,
    );
    if (error) {
      dispatch(
        openSnackbar({ message: message, open: true, severity: "error" }),
      );
    } else {
      dispatch(
        openSnackbar({
          message: `Successfully deleted '${selectedCalendar?.name}' calendar from this account.`,
          open: true,
          severity: "success",
        }),
      );
      onComplete();
    }
    setIsDeleting(false);
  };

  return (
    <UbicoDialog
      title={"Delete calendar"}
      open={open}
      handleClose={() => setShowDeleteConfirm(false)}
    >
      <DialogContent dividers>
        <Grid container spacing={theme.spacing(2)}>
          <Grid item>
            <Chip
              color="warning"
              size="small"
              label="Proceed with caution"
              icon={<WarningAmber fontSize="small" />}
              sx={{ p: theme.spacing(1) }}
            />
          </Grid>
          <Grid item>
            <Typography paragraph>
              You are about to delete <strong>{selectedCalendar?.name}</strong>{" "}
              calendar. Are you sure you want to continue?
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <UbicoButton onClick={() => setShowDeleteConfirm(false)}>
          Cancel
        </UbicoButton>
        <LoadingButton
          loading={isDeleting}
          variant="contained"
          color="error"
          onClick={onDeletePeople}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </UbicoDialog>
  );
};

export default CalendarDeleteDialog;
