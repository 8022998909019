import { Grid, useTheme } from "@mui/material";
import { FC, ReactElement, useEffect, useState } from "react";
import CalendarsTable from "./components/CalendarsTable";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import NylasService from "../../services/nylasService";
import { useDispatch } from "react-redux";
import { setSenders } from "../../redux/reducers/sendersReducer";
import UbicoLoading from "../../components/animation/UbicoLoading";

const Calendars: FC = (): ReactElement => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const senders = useSelector((state: RootState) => state.senders);
  const [loadingAccounts, setLoadingAccounts] = useState(false);

  const getSyncedAccounts = async () => {
    setLoadingAccounts(true);
    const { error, data } = await NylasService.getSyncedAccounts();
    if (!error && data) {
      // Also set redux
      dispatch(setSenders(data));
    }
    setLoadingAccounts(false);
  };

  useEffect(() => {
    if (senders.length === 0) {
      // Fetch senders
      getSyncedAccounts();
    }
  }, []);

  return (
    <Grid container spacing={theme.spacing(4)} justifyContent={"center"}>
      <Grid item xs={12}>
        {loadingAccounts ? <UbicoLoading /> : <CalendarsTable />}
      </Grid>
    </Grid>
  );
};

export default Calendars;
