import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccountUsage } from "../../constants/data-types";

const initialState: AccountUsage = {
  profiles_count: 0,
  generated_people_count: 0,
  people_count: 0,
  campaigns_count: 0,
  segments_count: 0,
  emails_sent_count: 0,
  conversations_count: 0,
  workspaces_count: 0,
  nylas_emails_count: 0,
  uav: 0,
};

export const accountUsageSlice = createSlice({
  name: "accountUsage",
  initialState,
  reducers: {
    setAccountUsage: (state, action: PayloadAction<AccountUsage>) => {
      state.profiles_count = action.payload.profiles_count;
      state.generated_people_count = action.payload.generated_people_count;
      state.people_count = action.payload.people_count;
      state.campaigns_count = action.payload.campaigns_count;
      state.segments_count = action.payload.segments_count;
      state.emails_sent_count = action.payload.emails_sent_count;
      state.conversations_count = action.payload.conversations_count;
      state.workspaces_count = action.payload.workspaces_count;
      state.nylas_emails_count = action.payload.nylas_emails_count;
      state.uav = action.payload.uav;
    },
  },
});

export const { setAccountUsage } = accountUsageSlice.actions;

export default accountUsageSlice.reducer;
