import { Link } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { FC, ReactElement } from "react";
import { useDispatch } from "react-redux";
import { openSnackbar } from "../../../redux/reducers/snackbarReducer";
import { CALENDAR_PREVIEW_LINK } from "../constants/calendar";

interface CalendarLinkRenderProps {
  params: GridRenderCellParams;
}

const CalendarLinkRender: FC<CalendarLinkRenderProps> = (
  props,
): ReactElement => {
  const { params } = props;

  const dispatch = useDispatch();

  const copyCalendarLink = () => {
    const calendarLink = `${CALENDAR_PREVIEW_LINK}/${params.row?.slug}`;
    navigator.clipboard.writeText(calendarLink);

    dispatch(
      openSnackbar({
        severity: "success",
        message: "Copied calendar link to clipboard!",
        open: true,
      }),
    );
  };

  return (
    <IconButton size="small" onClick={copyCalendarLink}>
      <Link fontSize="small" />
    </IconButton>
  );
};

export default CalendarLinkRender;
