const DATE_COLUMNS = [
  "created_at",
  "updated_at",
  "date_triggered",
  "date_added",
];

export const buildFilter = (
  params: any,
  column: string,
  operator: string,
  value: number | string | string[] | Date,
) => {
  if (value === null || value === undefined) value = "";

  if (DATE_COLUMNS.includes(column)) {
    column += `__date`;
    value = (value as Date).toISOString().slice(0, 10);
  }

  switch (operator) {
    case "contains":
      return (params[`${column}__icontains`] = value);
    case "equals":
      return (params[`${column}__iexact`] = value);
    case "startsWith":
      return (params[`${column}__istartswith`] = value);
    case "endsWith":
      return (params[`${column}__iendswith`] = value);
    case "isEmpty":
      return (params[`${column}__isnull`] = true);
    case "isNotEmpty":
      return (params[`${column}__isnull`] = false);
    case "isAnyOf":
      return (params[`${column}__in`] = value);
    case "is":
      return (params[`${column}`] = value);
    case "not":
      return (params[`${column}__not`] = value);
    case "after":
      return (params[`${column}__gt`] = value);
    case "onOrAfter":
      return (params[`${column}__gte`] = value);
    case "before":
      return (params[`${column}__lt`] = value);
    case "onOrBefore":
      return (params[`${column}__lte`] = value);
    case ">":
      return (params[`${column}__gt`] = value);
    case ">=":
      return (params[`${column}__gte`] = value);
    case "<":
      return (params[`${column}__lt`] = value);
    case "<=":
      return (params[`${column}__lte`] = value);
    default:
      return (params[`${column}`] = value);
  }
};
