import { AxiosService, UbicoAPIResponse } from "../api-provider";
import { NylasAccount } from "../constants/data-types";
import { API_PATH } from "../constants/paths";
import { GENERIC_ERROR_MESSAGE } from "../constants/snackbar-messages";
import { LeaderBoardOrderEnum } from "../pages/overview/constants/leaderboard";
import { FoldersEnum } from "../pages/settings/account/constants/email-folders";

const getSyncURL = async (
  email?: string,
): Promise<UbicoAPIResponse<object>> => {
  try {
    const params = { email: email || "" };
    const resp = await AxiosService.get(
      `${API_PATH}/emails/authenticate-url/`,
      { params: params },
    );
    return { error: false, data: resp.data.url, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const getSyncedAccountById = async (
  id: number,
): Promise<UbicoAPIResponse<NylasAccount>> => {
  try {
    const accounts = await AxiosService.get(`${API_PATH}/emails/${id}`);
    return { error: false, data: accounts.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const getSyncedAccounts = async (): Promise<
  UbicoAPIResponse<Array<NylasAccount>>
> => {
  try {
    const accounts = await AxiosService.get(`${API_PATH}/emails/`);
    return { error: false, data: accounts.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const updateSyncedAccount = async (
  id: number,
  data: NylasAccount,
): Promise<UbicoAPIResponse<object>> => {
  try {
    const accounts = await AxiosService.patch(
      `${API_PATH}/emails/${id}/`,
      data,
    );
    return { error: false, data: accounts.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const deleteSyncedAccount = async (
  id: number,
): Promise<UbicoAPIResponse<object>> => {
  try {
    const accounts = await AxiosService.delete(`${API_PATH}/emails/${id}/`);
    return { error: false, data: accounts.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const syncFolder = async (
  id: number,
  folder_id: FoldersEnum,
): Promise<UbicoAPIResponse<object>> => {
  try {
    const accounts = await AxiosService.post(
      `${API_PATH}/emails/${id}/folders/`,
      { folder_id: folder_id },
    );
    return { error: false, data: accounts.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const sweepFolders = async (id: number): Promise<UbicoAPIResponse<object>> => {
  try {
    const resp = await AxiosService.post(
      `${API_PATH}/emails/${id}/sweep-folders/`,
    );
    return { error: false, data: resp.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const getEmailsLeaderBoard = async (
  order: LeaderBoardOrderEnum,
): Promise<UbicoAPIResponse<NylasAccount[]>> => {
  try {
    const url = `${API_PATH}/emails/metrics/leaderboard/`;
    const params = { order: order };

    const resp = await AxiosService.get(url, { params: params });
    return { error: false, data: resp.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const NylasService = {
  getSyncURL,
  getSyncedAccounts,
  getSyncedAccountById,
  updateSyncedAccount,
  deleteSyncedAccount,
  syncFolder,
  getEmailsLeaderBoard,
  sweepFolders,
};

export default NylasService;
