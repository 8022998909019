import * as yup from "yup";

export const passwordValidator: any = () => {
  return yup
    .string()
    .required("Password is required")
    .min(8, "Password is too short - should be 8 chars minimum.");
};

export const passwordConfirmationValidator: any = (ref: string) => {
  return yup
    .string()
    .required("Password is required")
    .min(8, "Password is too short - should be 8 chars minimum.")
    .oneOf([yup.ref(ref)], "Your passwords do not match.");
};

export const emailValidator: any = () => {
  return yup
    .string()
    .email("Enter a valid email")
    .required("Email is required");
};

export const lastNameValidator: any = () => {
  return yup.string().required("Last Name is required");
};

export const firstNameValidator: any = () => {
  return yup.string().required("First Name is required");
};

export const numberValidator: any = (min: number, max: number) => {
  return yup
    .number()
    .min(min, `Must be at least ${min}`)
    .max(max, `Must be at most ${max}`)
    .typeError(`Must be a number between ${min} and ${max}`)
    .nullable();
};

export const requiredNumberValidator: any = (
  min: number,
  max: number,
  fieldName: string,
) => {
  return yup
    .number()
    .min(min, `Must be at least ${min}`)
    .max(max, `Must be at most ${max}`)
    .required(`${fieldName} is required`)
    .typeError(`Must be a number between ${min} and ${max}`);
};

export const requiredStringValidator: any = (
  fieldName: string,
  minLength: number,
  maxLength: number,
) => {
  return yup
    .string()
    .min(minLength, `Must be at least ${minLength} characters`)
    .max(maxLength, `Must be at most ${maxLength} characters`)
    .required(`${fieldName} is required`);
};

export const stringValidator: any = (minLength: number, maxLength: number) => {
  return yup
    .string()
    .min(minLength, `Must be at least ${minLength} characters`)
    .max(maxLength, `Must be at most ${maxLength} characters`)
    .nullable();
};

export const urlValidator: any = () => {
  return yup.string().nullable().notRequired().url("Must be a valid URL");
};

export const requiredUrlValidator: any = () => {
  return yup.string().url("Must be a valid URL").required("URL is required");
};

export const stringArrayValidator: any = () => {
  return yup.array().of(yup.string());
};

export const universalLoginEmailValidator: any = () => {
  return yup
    .string()
    .test("is-valid-email", "Email is not valid", (value: string) => {
      const [email1, email2] = value.split("::");
      const email1IsValid = yup.string().email().isValidSync(email1);
      return email2
        ? yup.string().email().isValidSync(email2) && email1IsValid
        : email1IsValid;
    })
    .required("Email is required");
};

export const domainValidator: any = (required = false) => {
  const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-_]{1,63}\.)+[a-zA-Z]{2,6}$/;
  let validator = yup.string().matches(domainRegex, "Invalid domain");

  if (required) {
    validator = validator.required("Domain is required");
  }
  return validator;
};
