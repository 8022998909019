export const enum NotificationTypes {
  ConversationGenerated = 1,
  PeopleAdded = 2,
  EngagedPeopleReport = 3,
  AccountLimitReached = 4,
  EmailSync = 5,
  EmailUnsync = 6,
  BillingUpdates = 7,
  AddedInWorkspace = 8,
  PerformanceReport = 9,
}

export const enum NotificationPreferenceTypes {
  ConversationGenerated = "conversation_generated",
  PeopleAdded = "people_added",
  EngagedPeopleReport = "engaged_people_report",
  PerformanceReport = "performance_report",
  ConversationFollowUp = "conversation_follow_up",
  NewCampaignSuggestions = "new_campaign_suggestions",
  AccountLimit = "account_limit_reached",
}

export const NotificationTypeLabels = {
  [NotificationTypes.ConversationGenerated]: "Conversations",
  [NotificationTypes.PeopleAdded]: "People",
  [NotificationTypes.EngagedPeopleReport]: "Engaged people report",
  [NotificationTypes.PerformanceReport]: "Performance report",
  [NotificationTypes.AccountLimitReached]: "Account",
  [NotificationTypes.EmailSync]: "Email sync",
  [NotificationTypes.EmailUnsync]: "Email unsync",
  [NotificationTypes.AddedInWorkspace]: "Added to workspace",
  [NotificationTypes.BillingUpdates]: "Billing update",
};
