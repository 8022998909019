import { CalendarMonth } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import { FC, ReactElement, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import UbicoCard from "../../../../../../components/custom/Card";
import { UbicoTextField } from "../../../../../../components/custom/textfields/TextField";
import {
  Account,
  CreditUsage as CreditUsageInterface,
  UbicoSubscription,
} from "../../../../../../constants/data-types";
import { RootState } from "../../../../../../redux/store";
import AccountService from "../../../../../../services/accountService";
import {
  CreditUsageOptions,
  CreditUsageType,
  CreditUsageTypeLabels,
  MAX_TRIAL_CREDITS,
} from "../../../constants/account-billing";
import CreditPeriodTotalUsage from "./usage/CreditPeriodTotalUsage";
import CreditPeriodUsage from "./usage/CreditPeriodUsage";
import TrialCreditUsage from "./usage/TrialCreditUsage";

const CreditUsage: FC = (): ReactElement => {
  const theme = useTheme();

  const account: Account = useSelector((state: RootState) => state.account);
  const subscription: UbicoSubscription = useSelector(
    (state: RootState) => state.billing.subscription,
  );

  const startDate = new Date(
    subscription?.current_period_start * 1000,
  ).toDateString();
  const endDate = new Date(
    subscription?.current_period_end * 1000,
  ).toDateString();

  const [loading, setLoading] = useState(true);
  const [filteredCreditUsage, setFilteredCreditUsage] = useState<
    CreditUsageInterface[]
  >([]);

  const [selectedUsageType, setSelectedUsageType] = useState<number>(-1);
  const [isCumulative, setIsCumulative] = useState(false);

  const isTrialCredits = subscription?.trial_credits_used < MAX_TRIAL_CREDITS;
  const planCredits =
    account?.plan_limits?.period_credits

  const getCreditUsage = async (
    usageType?: CreditUsageType,
    cumulative = false,
  ) => {
    setLoading(true);
    const { data, error } = await AccountService.getCreditUsage(
      usageType,
      cumulative,
    );

    if (data && !error) {
      if (!usageType && !cumulative) {
        setFilteredCreditUsage(data);
      } else setFilteredCreditUsage(data);
    }
    setLoading(false);
  };

  useEffect(() => {
    getCreditUsage();
  }, []);

  const onUsageTypeChange = (usageType: CreditUsageType) => {
    setSelectedUsageType(usageType);
    getCreditUsage(usageType, isCumulative);
  };

  const onCumulativeChange = (cumulative: boolean) => {
    setIsCumulative(cumulative);
    getCreditUsage(selectedUsageType, cumulative);
  };

  const usageTypeOptions = [-1, ...CreditUsageOptions];

  return (
    <Stack spacing={theme.spacing(2)}>
      {isTrialCredits && <TrialCreditUsage />}
      <UbicoCard
        cardTitle="Current period usage"
        cardSubtitle={
          <Stack direction={"row"} spacing={theme.spacing(1)}>
            <CalendarMonth
              fontSize="small"
              sx={{ color: theme.palette.text.secondary }}
            />
            <Typography variant="caption" color={"text.secondary"}>
              {startDate} - {endDate}
            </Typography>
          </Stack>
        }
        headerAction={
          <Stack
            width={"100%"}
            direction={"row"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            spacing={theme.spacing(2)}
          >
            <Autocomplete
              options={usageTypeOptions}
              size="small"
              sx={{ minWidth: 200 }}
              disabled={loading}
              disableClearable
              value={selectedUsageType}
              renderInput={(props) => (
                <UbicoTextField
                  fullWidth
                  {...props}
                  label="Usage type"
                  InputLabelProps={{ shrink: true }}
                />
              )}
              renderOption={(props: any, option: CreditUsageType) => (
                <Box component={"li"} {...props} key={option}>
                  {option >= 0 ? CreditUsageTypeLabels[option] : "All"}
                </Box>
              )}
              getOptionLabel={(option: CreditUsageType) =>
                option >= 0 ? CreditUsageTypeLabels[option] : "All"
              }
              onChange={(_, usageType: CreditUsageType) =>
                onUsageTypeChange(usageType)
              }
            />
            <FormControlLabel
              label={<Typography variant="caption">Cumulative</Typography>}
              disabled={loading}
              labelPlacement="bottom"
              control={
                <Switch
                  sx={{ p: 0, borderRadius: 30 }}
                  size="small"
                  checked={isCumulative}
                  onChange={(_, checked) => onCumulativeChange(checked)}
                />
              }
            />
          </Stack>
        }
      >
        <Stack spacing={theme.spacing(2)}>
          {planCredits > 0 && <CreditPeriodTotalUsage />}
          <CreditPeriodUsage
            creditUsage={filteredCreditUsage}
            loading={loading}
          />
        </Stack>
      </UbicoCard>
    </Stack>
  );
};

export default CreditUsage;
