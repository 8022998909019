import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { REACT_APP_BACKEND_URL } from "./constants";
import {
  HTTP_SERVER_ERROR_STATUS,
  HTTP_SUCCESS_STATUS,
  HTTP_UNAUTHORIZED_STATUS,
} from "./constants/http-status";
import { GENERIC_ERROR_MESSAGE } from "./constants/snackbar-messages";
import { authSlice } from "./redux/reducers/authReducer";
import { snackbarSlice } from "./redux/reducers/snackbarReducer";
import store from "./redux/store";
import AuthService from "./services/authService";

export enum UbicoResponseCodes {
  LimitReached = "limit_reached",
}

export interface UbicoAPIResponse<T> {
  error: boolean;
  message: string;
  data?: T;
  code?: UbicoResponseCodes;
}

export const AXIOS_CONFIG = {
  baseURL: REACT_APP_BACKEND_URL,
  headers: {
    "Content-type": "application/json",
  },
  withCredentials: true,
};

const AxiosService = axios.create(AXIOS_CONFIG);

AxiosService.interceptors.response.use(
  (res) => {
    const { baseURL, url } = res.config;
    console.debug("[Response]", baseURL || "" + url, res.status, res.data);
    return Promise.resolve(res);
  },
  (err) => {
    if (err?.response?.status == HTTP_UNAUTHORIZED_STATUS) {
      store.dispatch(authSlice.actions.setAuth({ is_logged_in: false }));
    }
    // Handle internal server error message
    if (err?.response?.status >= HTTP_SERVER_ERROR_STATUS) {
      err.message = GENERIC_ERROR_MESSAGE;
    }
    console.debug(
      "[Response]",
      err?.config?.baseURL + err?.config?.url,
      err?.response?.status,
      err?.response?.data,
    );
    return Promise.reject(err);
  },
);

const refreshAuthLogic = async (failedRequest: any) => {
  // Try to fetch a new access token from refresh token
  if (failedRequest?.response?.status == HTTP_UNAUTHORIZED_STATUS) {
    try {
      const resp = await AuthService.autoRefreshToken();
      // If the user's refresh token is expired, we log him out.
      if (resp?.status !== HTTP_SUCCESS_STATUS) {
        logoutUser();
      }
    } catch (error: any) {
      logoutUser();
    }
  } else {
    store.dispatch(authSlice.actions.setAuth({ is_logged_in: true }));
  }
};

const logoutUser = () => {
  store.dispatch(authSlice.actions.setAuth({ is_logged_in: false }));
  store.dispatch(
    snackbarSlice.actions.openSnackbar({
      message: "Your session has expired. Please login again.",
      severity: "error",
      open: true,
    }),
  );
};

createAuthRefreshInterceptor(AxiosService, refreshAuthLogic);

export async function fetcher<T = any>(url: string) {
  const res = await AxiosService.get<T>(url);
  return res.data;
}

export { AxiosService };
