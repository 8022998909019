import { Visibility } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { FC, ReactElement } from "react";
import { CALENDAR_PREVIEW_LINK } from "../constants/calendar";

interface CalendarPreviewRenderProps {
  params: GridRenderCellParams;
}

const CalendarPreviewRender: FC<CalendarPreviewRenderProps> = (
  props,
): ReactElement => {
  const { params } = props;

  const openPreview = () => {
    window.open(`${CALENDAR_PREVIEW_LINK}/${params.row?.slug}`);
  };

  return (
    <IconButton size="small" onClick={openPreview}>
      <Visibility fontSize="small" />
    </IconButton>
  );
};

export default CalendarPreviewRender;
