import { Dayjs } from "dayjs";
import { SegmentStatsFrequencies } from "../pages/overview/constants/segment-stats";

export const formatOffset = (offset: string) => {
  return `(GMT ${offset})`;
};

export const getTimeSince = (date: string) => {
  /**
   * Function that returns the time different as a string between now and a previous date.
   */
  if (!date) return "";
  const oldDate = new Date(date);
  const currentDate = new Date();
  const diff = currentDate.getTime() - oldDate.getTime();
  const seconds = Math.floor(diff / 1000);

  let interval = seconds / 31536000;

  if (interval > 1) {
    const value = Math.floor(interval);
    return value + (value > 1 ? " years" : " year") + " ago";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    const value = Math.floor(interval);
    return value + (value > 1 ? " months" : " month") + " ago";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    const value = Math.floor(interval);
    return value + (value > 1 ? " days" : " day") + " ago";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    const value = Math.floor(interval);
    return value + (value > 1 ? " hours" : " hour") + " ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    const value = Math.floor(interval);
    return value + (value > 1 ? " minutes" : " minute") + " ago";
  }
  const value = Math.floor(interval);
  if (value === null || value === undefined) return "";
  if (value < 1) return "Just now";
  return value + (value > 1 ? " seconds" : " second") + " ago";
};

export const sortByDate = (objects: object[], dateKey: string) => {
  return objects.slice().sort((a: any, b: any) => {
    return +new Date(b[dateKey]) - +new Date(a[dateKey]);
  });
};

export const formatDate = (date: Dayjs) => {
  /**
   * Formats the date in a readable format for the user
   */
  return date?.format("MMMM D, YYYY");
};

export const renderDate = (date: Dayjs, frequency: SegmentStatsFrequencies) => {
  /**
   * Renders a date in a format based on frequency for graph tooltip.
   */
  if (typeof date?.format !== "function") return;
  if (frequency === SegmentStatsFrequencies.Monthly) {
    return date?.format("MMMM");
  } else {
    return date?.format("MMMM D, YYYY");
  }
};
