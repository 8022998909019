import {
  ArrowCircleRightRounded as ArrowCircleRightRoundedIcon,
  Notifications,
  Settings as SettingsIcon,
  Support,
} from "@mui/icons-material";
import { Icon } from "@mui/material";
import { Route } from "./routeInterface";

import NotificationsComponent from "../components/notifications/Notifications";
import NotificationsCountChip from "../components/notifications/NotificationsCountChip";
import { UBICO_SUPPORT_PAGE } from "../constants/urls";
import { ADMIN_ROLE, COLLABORATOR_ROLE } from "../constants/user-roles";
import { AccountSetup } from "../loadables";
import { calendarRoutes } from "./calendarRoutes";
import { campaignRoutes } from "./campaignRoutes";
import { newslettersRoutes } from "./newslettersRoutes";
import { overviewRoutes } from "./overviewRoutes";
import { peopleRoutes } from "./peopleRoutes";
import { segmentRoutes } from "./segmentRoutes";
import { settingsRoutes } from "./settingsRoutes";
import { taskRoutes } from "./taskRoutes";
import { templateRoutes } from "./templateRoutes";

export const LOGIN_ROUTE = "/login";
export const LOGOUT_ROUTE = "/logout";
export const SETUP_ROUTE = "/setup";
export const HOME_ROUTE = "/";
export const OVERVIEW_BILLING_ROUTE = "/settings/billing";
export const BILLING_ROUTE = "/settings/billing/manage";

export const appRoutes: Array<Route> = [
  {
    key: "notifications-route",
    label: "Notifications",
    enabled: true,
    drawerComponent: <NotificationsComponent />,
    isBottomTab: true,
    order: 0,
    tabInfo: {
      id: "notifications",
      startIcon: <Icon component={Notifications} />,
      endIcon: <NotificationsCountChip />,
      alwaysShowEndIcon: true,
    },
    leastRole: COLLABORATOR_ROLE,
    isDrawer: true,
  },
  {
    key: "settings-route",
    label: "Settings",
    path: "settings",
    enabled: true,
    isBottomTab: true,
    nestedRoutes: settingsRoutes,
    order: 1,
    tabInfo: {
      id: "settings",
      startIcon: <Icon component={SettingsIcon} height={22} width={22} />,
      endIcon: <Icon component={ArrowCircleRightRoundedIcon} />,
    },
    leastRole: COLLABORATOR_ROLE,
    isNestedTabs: true,
  },
  {
    key: "account-setup",
    label: "Account setup",
    path: "setup",
    enabled: true,
    leastRole: ADMIN_ROLE,
    component: AccountSetup,
  },
  ...overviewRoutes,
  ...peopleRoutes,
  ...segmentRoutes,
  ...campaignRoutes,
  ...calendarRoutes,
  ...templateRoutes,
  ...taskRoutes,
  ...newslettersRoutes,
  {
    key: "support-route",
    label: "Support",
    enabled: true,
    isBottomTab: true,
    order: 2,
    tabInfo: {
      id: "support",
      startIcon: <Icon component={Support} height={22} width={22} />,
      endIcon: null,
    },
    leastRole: COLLABORATOR_ROLE,
    onClick: () => window.open(UBICO_SUPPORT_PAGE, "_blank"),
  },
];
