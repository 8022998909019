import { Chip } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { FC, ReactElement } from "react";
import { useSelector } from "react-redux";
import { NylasAccount } from "../../../constants/data-types";
import { RootState } from "../../../redux/store";

interface CalendarAccountRenderProps {
  params: GridRenderCellParams;
}

const CalendarAccountRender: FC<CalendarAccountRenderProps> = (
  props,
): ReactElement => {
  const { params } = props;

  const senders = useSelector((state: RootState) => state.senders);

  const sender: NylasAccount = senders.find(
    (s: NylasAccount) => s.id === params?.row?.account_id,
  );

  return <Chip size="small" label={sender?.email} />;
};

export default CalendarAccountRender;
