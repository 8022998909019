import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { FormikValues, getIn, useFormik } from "formik";
import { FC, ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UbicoDialog from "../../../components/custom/Dialog";
import UbicoButton from "../../../components/custom/buttons/Button";
import { UbicoTextField } from "../../../components/custom/textfields/TextField";
import {
  ACCOUNT_RUNNING,
  EMAIL_SYNC_STATUS_CHIPS,
} from "../../../constants/chips/email-sync-status";
import { NylasAccount } from "../../../constants/data-types";
import { openSnackbar } from "../../../redux/reducers/snackbarReducer";
import { RootState } from "../../../redux/store";
import CalendarService from "../../../services/calendarService";
import { EmailOptions } from "../../campaigns/constants/workflow-builder/email-node";
import { CalendarFields } from "../constants/calendar";
import { calendarInitialValues, calendarSchema } from "../forms/calendarForm";

interface CalendarCreateDialogProps {
  onClose(): void;
  open?: boolean;
}

const CalendarCreateDialog: FC<CalendarCreateDialogProps> = (
  props,
): ReactElement => {
  const { onClose, open } = props;

  const theme = useTheme();
  const dispatch = useDispatch();

  const senders = useSelector((state: RootState) => state.senders);

  const [isCreatingCalendar, setIsCreatingCalendar] = useState(false);

  const calendarForm = useFormik({
    initialValues: calendarInitialValues,
    validationSchema: calendarSchema,
    onSubmit: () => {},
  });

  const createCalendar = async () => {
    setIsCreatingCalendar(true);
    await calendarForm.submitForm();
    if (Object.keys(calendarForm.errors).length <= 0) {
      const resp = await CalendarService.createCalendar(
        calendarForm.values[CalendarFields.EmailAccountId],
        calendarForm.values,
      );
      if (!resp.error) {
        dispatch(
          openSnackbar({
            open: true,
            severity: "success",
            message: "Successfully created your calendar!",
          }),
        );
        onClose();
      } else {
        dispatch(
          openSnackbar({
            open: true,
            severity: "error",
            message: resp.message,
          }),
        );
      }
    }
    setIsCreatingCalendar(false);
  };

  const formValues = calendarForm.values as FormikValues;
  const formErrors = calendarForm.errors as FormikValues;
  const formTouched = calendarForm.touched as FormikValues;

  const calendarAccountIdValue = getIn(
    formValues,
    CalendarFields.EmailAccountId,
  );
  const calendarNameValue = getIn(formValues, CalendarFields.Name);
  const calendarSlugValue = getIn(formValues, CalendarFields.Slug);

  const calendarAccountIdError = getIn(
    formErrors,
    CalendarFields.EmailAccountId,
  );
  const calendarNameError = getIn(formErrors, CalendarFields.Name);
  const calendarSlugError = getIn(formErrors, CalendarFields.Slug);

  const calendarAccountIdTouched = getIn(
    formTouched,
    CalendarFields.EmailAccountId,
  );
  const calendarNameTouched = getIn(formTouched, CalendarFields.Name);
  const calendarSlugTouched = getIn(formTouched, CalendarFields.Slug);

  const updateSender = (_: any, value: NylasAccount) => {
    calendarForm.setFieldValue(CalendarFields.EmailAccountId, value.id);
  };

  useEffect(() => {
    if (open) calendarForm.resetForm();
  }, [open]);

  const senderData = senders.find((s) => s.id === calendarAccountIdValue);

  return (
    <UbicoDialog title={"Create a calendar"} open={open} handleClose={onClose}>
      <DialogContent dividers sx={{ p: theme.spacing(4), width: 500 }}>
        <Stack spacing={theme.spacing(2)}>
          <UbicoTextField
            label="Calendar Name"
            variant="outlined"
            size="small"
            autoFocus
            fullWidth
            required
            name={CalendarFields.Name}
            value={calendarNameValue}
            error={Boolean(calendarNameError && calendarNameTouched)}
            helperText={
              calendarNameTouched && calendarNameError ? calendarNameError : ""
            }
            onBlur={calendarForm.handleBlur}
            onChange={calendarForm.handleChange}
          />
          <UbicoTextField
            label="Calendar slug"
            variant="outlined"
            size="small"
            fullWidth
            required
            placeholder="your-calendar-slug"
            InputLabelProps={{ shrink: true }}
            name={CalendarFields.Slug}
            value={calendarSlugValue}
            error={Boolean(calendarSlugError && calendarSlugTouched)}
            helperText={
              calendarSlugTouched && calendarSlugError
                ? calendarSlugError
                : `https://schedule.nylas.com/${calendarSlugValue ? calendarSlugValue : "your-calendar-slug"}`
            }
            onBlur={calendarForm.handleBlur}
            onChange={calendarForm.handleChange}
          />
          <FormControl
            size="small"
            fullWidth
            error={Boolean(calendarAccountIdError && calendarAccountIdTouched)}
          >
            <Autocomplete
              options={senders}
              fullWidth
              size="small"
              value={senderData || null}
              renderInput={(props) => (
                <UbicoTextField
                  label="Send from"
                  name={EmailOptions.Sender}
                  error={Boolean(
                    calendarAccountIdError && calendarAccountIdTouched,
                  )}
                  helperText={
                    calendarAccountIdError && calendarAccountIdTouched
                      ? calendarAccountIdError
                      : ""
                  }
                  {...props}
                />
              )}
              renderOption={(props, option: NylasAccount) => {
                return (
                  <li {...props} key={option.id}>
                    <Stack
                      width={"100%"}
                      direction={"row"}
                      overflow={"hidden"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Typography
                        variant="body1"
                        noWrap
                        textOverflow={"ellipsis"}
                      >
                        {option.email}
                      </Typography>
                      {EMAIL_SYNC_STATUS_CHIPS[option.sync_state]}
                    </Stack>
                  </li>
                );
              }}
              getOptionLabel={(o) => o.email}
              isOptionEqualToValue={(o, v) => o.id === v.id}
              onBlur={calendarForm.handleBlur}
              onChange={updateSender}
              disableClearable
              getOptionDisabled={(s: NylasAccount) =>
                s.sync_state !== ACCOUNT_RUNNING
              }
            />
            {calendarAccountIdTouched && calendarAccountIdError && (
              <FormHelperText>{calendarAccountIdError}</FormHelperText>
            )}
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <UbicoButton onClick={onClose}>Cancel</UbicoButton>
        <LoadingButton
          loading={isCreatingCalendar}
          variant="contained"
          onClick={createCalendar}
        >
          Create
        </LoadingButton>
      </DialogActions>
    </UbicoDialog>
  );
};

export default CalendarCreateDialog;
